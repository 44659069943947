import '../styles/AdoptionList.css';
import { useContext, useState, useEffect } from "react";
import AdoptionProvider from "../providers/AdoptionProvider";
import AdoptionListItem from "../components/AdoptionListItem";
import SectionHeader from "../components/SectionHeader";
function AdoptionList() {

    document.title = 'Adoptions | Pets App';

    const Adoption = useContext(AdoptionProvider);
    const [adoptions, setAdoptions] = useState(Adoption.getAdoptions());


    const updateAdoptions = () => {
        setAdoptions(Adoption.getAdoptions());
    };

    const cancelAdoption = item => {
        if(Adoption.removeAdoption(item)) {
            updateAdoptions();
        }
    };

    useEffect(() => {

        updateAdoptions(); // eslint-disable-next-line
    }, []);

    return (
        <section className="app-adoption-list">
            <SectionHeader title="Your Adoptions" />
            {adoptions.length > 0 ?
                <div className="app-adoption-list-items">
                    {adoptions.map((item) => (
                        <AdoptionListItem key={item._id} item={item} cancelAdoption={cancelAdoption} />
                        ))}
                </div> :

                <p>No adoptions for now.</p>}
        </section>
    );
}

export default AdoptionList;