import {searchPets} from "../api/pets";
import { useLoaderData } from 'react-router-dom';
import Pets from "../components/Pets";
import '../styles/SearchResults.css';
import SectionHeader from "../components/SectionHeader";
function SearchResults() {
    document.title = 'Search Results | Pets App';
    const { pets } = useLoaderData();

    return (
        <div className="app-search-results">
            <SectionHeader title="Search results" />
            {Array.isArray(pets) && pets.length > 0 ?
                <Pets items={pets} />
            : <p>No results.</p>}
        </div>
    );
}

export async function loader({ request }) {
    const url = new URL(request.url);
    const searchTerm = url.searchParams.get('s');
    const results = await searchPets(searchTerm);
    const { pets } = results;
    return { pets };
}

export default SearchResults;