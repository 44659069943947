import Icon from "./Icon";
import logo from './svg/logo.svg';
function Logo() {
    return (
        <Icon>
            <img src={logo} alt="Pets App" />
        </Icon>
    );
}

export default Logo;