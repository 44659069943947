import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts/Poppins/Poppins-Regular.ttf';
import './fonts/Poppins/Poppins-Italic.ttf';
import './fonts/Poppins/Poppins-Bold.ttf';
import './fonts/Poppins/Poppins-Black.ttf';
import './styles/index.css';
import App from './components/App';
import Home from "./pages/Home";
import PetsIndex from "./pages/PetsIndex";
import PetDetails, { loader as petDetailsLoader } from "./pages/PetDetails";
import AdoptionList from "./pages/AdoptionList";
import SearchResults, { loader as searchResultsLoader } from "./pages/SearchResults";
import ThankYou from "./pages/ThankYou";
import DonationList from "./pages/DonationList";
import Donate from "./pages/Donate";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
    {
        path: '/',
        Component: App,
        children: [
            {
                index: true,
                Component: Home
            },
            {
                path: '/pets',
                Component: PetsIndex
            },
            {
                path: '/pets/:slug',
                Component: PetDetails,
                loader: petDetailsLoader
            },
            {
                path: '/adoptions',
                Component: AdoptionList
            },
            {
                path: '/donations',
                Component: DonationList
            },
            {
                path: '/donate',
                Component: Donate
            },
            {
                path: '/search',
                Component: SearchResults,
                loader: searchResultsLoader
            },
            {
                path: '/thank-you',
                Component: ThankYou
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);
