import { Link } from "react-router-dom";
import AssetsConfig from "../config/assets";
import '../styles/Pet.css';

function Pet({ item, showImageOverlay }) {
    return (
        <article className="pet">
            <figure onClick={() => showImageOverlay(item)}>
                <img src={`${AssetsConfig.imagePath}${item.image}`} alt={item.name} />
            </figure>
            <header>
                <h2>{item.name}</h2>
            </header>

            <div className="more-wrap">
                <Link to={`/pets/${item.slug}`} className="more-link">
                    View More
                </Link>
            </div>
        </article>
    );
}

export default Pet;