import '../styles/DonationItem.css';
function DonationItem({ donation }) {
    return (
        <div className="donation">
            <div className="donation-date">
                {new Date(donation.date).toLocaleDateString()}
            </div>
            <div className="donation-id">
                #{donation.id}
            </div>
            <div className="donation-info">
                <div>{donation.customer.name}</div>
                <div>{donation.customer.email}</div>
            </div>
            <div className="donation-amount">
                <div>{donation.payment.currency} {donation.payment.amount}</div>
                <div>{donation.payment.description}</div>
            </div>
        </div>
    );
}

export default DonationItem;