import Order from "../session/Order";
import '../styles/DonationList.css';
import DonationItem from "../components/DonationItem";
import SectionHeader from "../components/SectionHeader";
function DonationList() {
    const donations = Order.getOrders();
    return (
        <div className="app-donations">
            <SectionHeader title="Your Donations" />
            {donations.length > 0 ?
                donations.map((donation) => (
                    <DonationItem key={donation.id} donation={donation} />
                ))
            : <p>No donations for now.</p>}
        </div>
    );
}

export default DonationList;