import Payment from "../components/Payment";
import '../styles/Donate.css';
import SectionHeader from "../components/SectionHeader";

function Donate() {
    document.title = 'Donate | Pets App';
    return (
        <div className="app-donate">
            <SectionHeader title="Donate" />
            <p className="app-message">
                Using the <a rel="noreferrer" target="_blank" href="https://developer.paypal.com/tools/sandbox/">Paypal sandbox <i
                className="fa-solid fa-arrow-up-right-from-square"></i></a>
                <q>that simulates the live PayPal production environment</q>.
            </p>
            <Payment />
        </div>
    );
}

export default Donate;