import Icon from "./Icon";
import donation from './svg/donation.svg';

function Donation() {
    return (
        <Icon>
            <img src={donation} alt="" />
        </Icon>
    )
}

export default  Donation;