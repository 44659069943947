import '../styles/AlertOverlay.css';
import { Link } from "react-router-dom";

function AlertOverlay({ title, message, closeAlert }) {
    return (
        <div className="app-alert-overlay" role="alert">
            <div className="app-alert-overlay-content">
                <header>
                    <h2>{title}</h2>
                    <button onClick={() => closeAlert()}>&times;</button>
                </header>
                <p>{message}</p>
                <footer>
                    <Link to="/adoptions" className="more-link">
                        View adoptions
                    </Link>
                </footer>
            </div>
        </div>
    );
}

export default AlertOverlay;