import utils from "../utils";

const key = `donations-${utils.randomSuffix()}`;
export default class Order {
    constructor(details) {
        this.details = details;
    }

    static getOrders() {
        return localStorage.getItem(key) !== null ?
            JSON.parse(localStorage.getItem(key)) : [];
    }

    save() {
        if(!this.details) {
            return false;
        }
        if(!this.details.status || this.details.status.toLowerCase() !== 'completed') {
            return false;
        }

        const data = {
            date: new Date(this.details.create_time),
            id: this.details.id,
            customer: {
                name: `${this.details.payer.name.given_name} ${this.details.payer.name.surname}`,
                email: `${this.details.payer.email_address}`
            },
            payment: {
                description: this.details.purchase_units[0].soft_descriptor,
                amount: this.details.purchase_units[0].amount.value,
                currency: this.details.purchase_units[0].amount.currency_code
            }
        };
        const orders = localStorage.getItem(key);
        if(orders === null) {
            localStorage.setItem(key, JSON.stringify([data]));
        } else {
            const ordersArr = JSON.parse(orders);
            const update = [data, ...ordersArr];
            localStorage.setItem(key, JSON.stringify(update));
        }
        return true;
    }
}