import '../styles/App.css';
import Header from "./Header";
import Footer from "./Footer";
import { Outlet, useLocation } from "react-router-dom";
import Container from "./Container";
import SearchOverlay from "./SearchOverlay";
import {useEffect, useState} from "react";
import AdoptionProvider from "../providers/AdoptionProvider";
import Adoption from "../session/Adoption";
import Preloader from "./Preloader";

function App() {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isNavigationVisible, setIsNavigationVisible] = useState(false);
  const [canHaveBackButton, setCanHaveBackButton] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const location = useLocation();
  useEffect(() => {
      if(/pets\/?$/g.test(location.pathname) || location.pathname === '/') {
          setIsLoaded(false);
      }
      setIsNavigationVisible(false);
      setCanHaveBackButton(location.pathname !== '/');
  }, [location]);
  return (
    <main className="app">
      <Header canHaveBackButton={canHaveBackButton} setIsSearchVisible={setIsSearchVisible} isNavigationVisible={isNavigationVisible} setIsNavigationVisible={setIsNavigationVisible} />
      <Container>
          <AdoptionProvider.Provider value={new Adoption()}>
              <Outlet context={[isLoaded, setIsLoaded]}/>
          </AdoptionProvider.Provider>
      </Container>
      <Footer />
      <SearchOverlay visible={isSearchVisible} setIsSearchVisible={setIsSearchVisible} />
        {!isLoaded && <Preloader />}
    </main>
  );
}

export default App;
