import Next from "./ui/icons/Next";
import Previous from "./ui/icons/Previous";
import '../styles/Pagination.css';
function Pagination({ page, pages, getPetsPage, setPage }) {
    const nextPage = () => {
        const next = parseInt(page, 10) + 1;
        getPetsPage(next);
        return setPage(next);
    };

    const previousPage = () => {
        const previous = parseInt(page, 10) - 1;
        getPetsPage(previous);
        return setPage(previous);
    };
    return (
        <nav className="app-pagination">
            {page > 1 && <button onClick={() => previousPage()}><Previous /></button>}
            {page < pages && <button onClick={() => nextPage()}><Next /></button>}
        </nav>
    )
}

export default Pagination;