import '../styles/ThankYou.css';
import SectionHeader from "../components/SectionHeader";
function ThankYou() {
    return (
        <div className="app-thank-you">
            <SectionHeader title="Thank you!" />
            <p className="app-message">Your donation has been received.</p>
        </div>
    );
}

export default ThankYou;