import Pet from "./Pet";
import '../styles/Pets.css';
import ImageOverlay from "./ImageOverlay";
import { useState } from "react";

function Pets({ items }) {
    const [isImageOverlayVisible, setIsImageOverlayVisible] = useState(false);
    const [itemDetails, setItemDetails] = useState({
        image: '',
        name: '',
        description: ''
    });

    const showImageOverlay = item => {
        setItemDetails(item);
        setIsImageOverlayVisible(true);
    };

    return (
       <>
        <ul className="pets">
            {items.map((item) => (
                <li key={item._id}>
                    <Pet item={item} showImageOverlay={showImageOverlay} />
                </li>
            ))}
        </ul>
        <ImageOverlay details={itemDetails} setIsImageOverlayVisible={setIsImageOverlayVisible} isImageOverlayVisible={isImageOverlayVisible} />
       </>
    );
}

export default Pets;