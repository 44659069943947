import ApiConfig from "../config/api";
import axios from "axios";

export async function getPets(page = 1) {
    let url = ApiConfig.url + ApiConfig.endpoints.pets;
    if(parseInt(page, 10) > 1) {
        url += `?page=${page}`;
    }
    try {
        const response = await axios.get(url);
        return response.data;
    } catch(err) {
        return err;
    }

}

export async function getPet(slug) {
    const url = ApiConfig.url + ApiConfig.endpoints.pets + '/' + slug;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch(err) {
        return err;
    }
}

export async function searchPets(query) {
    const url = ApiConfig.url + ApiConfig.endpoints.search + `?s=${encodeURIComponent(query)}`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch(err) {
        return err;
    }
}