import Icon from "./Icon";
import adoption from './svg/adoption.svg';
function Adoption() {
    return (
        <Icon>
            <img src={adoption} alt="" />
        </Icon>
    );
}

export default Adoption;