import '../styles/Preloader.css';

function Preloader() {
    return (
        <div className="app-preloader" aria-hidden="true">
            <div className="app-loader"></div>
        </div>
    );
}

export default Preloader;