import Icon from "./Icon";
import search from './svg/search.svg';
function Search() {
    return (
        <Icon>
            <img src={search} alt="" />
        </Icon>
    );
}

export default Search;