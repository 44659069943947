const navigation = [
    {
        label: 'Home',
        path: '/'
    },
    {
        label: 'Pets',
        path: '/pets'
    }
];

export default navigation;