import Icon from "./Icon";
import menu from './svg/menu.svg';

function Menu() {
    return (
        <Icon>
            <img src={menu} alt="" />
        </Icon>
    )
}

export default  Menu;