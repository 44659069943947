import AssetsConfig from "../config/assets";
import '../styles/AdoptionListItem.css';
function  AdoptionListItem({ item, cancelAdoption }) {
    return (
        <div className="app-adoption-list-item">
            <figure>
                <img src={`${AssetsConfig.imagePath}${item.image}`} alt={item.name} />
            </figure>
            <div className="app-adoption-list-item-name">
                {item.name}
            </div>
            <div className="app-adoption-list-item-actions">
                <button onClick={() => cancelAdoption(item)}>&times;</button>
            </div>
        </div>
    )
}

export default  AdoptionListItem;