import utils from "../utils";
export default class Adoption {
    constructor() {
        const suffix = utils.randomSuffix();
        this.session = localStorage;
        this.key = `adoptions-${suffix}`;
        this.adoptions = this.getAdoptions();

    }

    adoptionExists(id) {
        if(this.adoptions.length === 0) {
            return false;
        }
        const found = this.adoptions.find(a => a._id === id);
        return found ? true : false;
    }

    addAdoption(item) {
        if(this.adoptionExists(item._id)) {
            return false;
        }
        this.adoptions.push(item);
        this.save();
        return true;
    }

    removeAdoption(item) {
        if(!this.adoptionExists(item._id)) {
            return false;
        }
        const adoptions = this.adoptions.filter(a => a._id !== item._id);
        this.adoptions = adoptions;
        this.save();
        return true;
    }

    getAdoptions() {
        return this.session.getItem(this.key) !== null ? JSON.parse(this.session.getItem(this.key)) : [];
    }

    save() {
        this.session.setItem(this.key, JSON.stringify(this.adoptions));
    }
}