import PetsIcon from "./ui/icons/PetsIcon";
import Adoption from "./ui/icons/Adoption";
import Donation from "./ui/icons/Donation";
import '../styles/Footer.css';
import {Link} from "react-router-dom";

function Footer() {
    return (
        <footer className="app-footer">
            <div className="app-footer-actions">
                <Link to="/pets" className="app-footer-action"><PetsIcon /></Link>
                <Link to="/adoptions" className="app-footer-action"><Adoption /></Link>
                <Link to="/donations" className="app-footer-action"><Donation /></Link>
                <Link to="/donate" className="app-footer-action donate-btn">
                    <i className="fa-solid fa-hand-holding-dollar"></i>
                    Donate
                </Link>
            </div>
        </footer>
    );
}

export  default Footer;