import Icon from "./Icon";
import previous from './svg/previous.svg';
function Previous() {
    return (
        <Icon>
            <img src={previous} alt="" />
        </Icon>
    );
}

export default Previous;