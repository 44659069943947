import navigation from "../config/navigation";
import { NavLink } from "react-router-dom";
import '../styles/Navigation.css';
import {useState, useEffect} from "react";
function Navigation({ visible }) {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        setIsVisible(visible);
    }, [visible]);

    return (
        <nav className={isVisible ? 'app-navigation visible' : 'app-navigation'}>
            <ul>
                {navigation.map((item, i) => (
                    <li key={i}>
                        <NavLink to={`${item.path}`} className={({ isActive, isPending }) =>
                            isPending ? 'pending' : isActive ? 'active' : ''
                        }>
                            {item.label}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default Navigation;