import Icon from "./Icon";
import next from './svg/next.svg';
function Next() {
    return (
        <Icon>
            <img src={next} alt="" />
        </Icon>
    );
}

export default Next;