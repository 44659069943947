import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {useState} from "react";
import '../styles/Payment.css';
import Order from "../session/Order";

function Payment() {
    const [amount, setAmount] = useState('0.99');

    sessionStorage.setItem('amount', amount);
    const handleAmount = evt => {
        const value = parseFloat(evt.target.value);
        setAmount(evt.target.value);
        if(!isNaN(value)) {
            sessionStorage.setItem('amount', value);
        } else {
            sessionStorage.setItem('amount', '0.99');
        }
    };
    return (
        <div className="app-payment">
            <form onSubmit={(e) => e.preventDefault()}>
                <div>
                    <label htmlFor="amount">Make a donation</label>
                    <input type="text" id="amount" value={amount} onChange={(e) => handleAmount(e)}  />
                </div>
                <div className="app-payment-paypal">
                    <PayPalScriptProvider options={{ "client-id": "AZ25PO_-Up5MPRCd2Gro_vwupWK3NgDdESXyr-lCVc5B6GjzKuUFPhonVWlgW75VJRJ-qWVXWKcqsTzb" }}>
                        <PayPalButtons
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                value: parseFloat(sessionStorage.getItem('amount')).toFixed(2)
                                            }
                                        }
                                    ]
                                });
                            }}
                            onApprove={(data, actions) => {
                                return actions.order.capture().then((details) => {
                                    const order = new Order(details);
                                    if(order.save()) {
                                        window.location = '/thank-you';
                                    }
                                });
                            }}
                        />
                    </PayPalScriptProvider>
                </div>
            </form>
        </div>
    )
}

export default Payment;