import AssetsConfig from "../config/assets";
import '../styles/ImageOverlay.css';
function ImageOverlay({ details, isImageOverlayVisible, setIsImageOverlayVisible }) {
    return (
        <div className={isImageOverlayVisible ? 'app-image-overlay visible' : 'app-image-overlay'}>
            <button className="app-image-overlay-close" onClick={() => setIsImageOverlayVisible(false)}>&times;</button>
            <figure>
                <img src={details.image ? `${AssetsConfig.imagePath}${details.image}` : ''} alt={details.name} />
                <figcaption>{details.description}</figcaption>
            </figure>
        </div>
    );
}

export default ImageOverlay;