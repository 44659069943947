import {Link, useNavigate} from "react-router-dom";
import Menu from "./ui/icons/Menu";
import Search from "./ui/icons/Search";
import Logo from "./ui/icons/Logo";
import Navigation from "./Navigation";
import '../styles/Header.css';
import Previous from "./ui/icons/Previous";

function Header({ canHaveBackButton, setIsSearchVisible, isNavigationVisible, setIsNavigationVisible }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <header className="app-header">
            <div className="app-header-actions">
                {canHaveBackButton && <button className="back" onClick={() => goBack()}><Previous /></button> }
                <button className="toggle-navigation" onClick={() => setIsNavigationVisible(!isNavigationVisible)}>
                    <Menu />
                </button>
                <button className="toggle-search" onClick={() => setIsSearchVisible(true)}>
                    <Search />
                </button>
            </div>

            <div className="app-logo">
                <Link to="/">
                    <Logo />
                </Link>
            </div>
            <Navigation visible={isNavigationVisible} />
        </header>
    )
}

export default Header;