import { getPet } from "../api/pets";
import { useLoaderData } from 'react-router-dom';
import AssetsConfig from "../config/assets";
import '../styles/PetDetails.css';
import { useContext, useState } from "react";
import AdoptionProvider from "../providers/AdoptionProvider";
import AlertOverlay from "../components/AlertOverlay";

export async function loader({ params }) {
    const details = await getPet(params.slug);
    return { details };
}

function PetDetails() {
    const { details } = useLoaderData();
    const Adoption = useContext(AdoptionProvider);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    document.title = `${details.name} | Pets App`;

    const handleAdoption = () => {

        if(Adoption.addAdoption(details)) {
            setAlertTitle('Thanks!');
            setAlertMessage(`You have chosen ${details.name} for adoption.`);
        } else {
            setAlertTitle('Already adopted');
            setAlertMessage(`You have already chosen ${details.name} for adoption.`);
        }
        setIsAlertOpen(true);
    };

    const closeAlert = ()=> setIsAlertOpen(false);

    return (
        <article className="app-pet-details">
            <header>
                <h1>{details.name}</h1>
            </header>
            <div className="app-pet-details-wrap">
                <figure>
                    <img src={`${AssetsConfig.imagePath}${details.image}`} alt={details.name} />
                </figure>
                <div className="app-pet-details-description">
                    <p>{details.description}</p>
                    <div className="app-pet-details-actions">
                        <button className="more-link" onClick={() => handleAdoption()}>Adopt {details.name}</button>
                    </div>
                </div>

            </div>
            {isAlertOpen && <AlertOverlay closeAlert={closeAlert} title={alertTitle} message={alertMessage} /> }
        </article>
    );
}

export default PetDetails;