import { getPets } from "../api/pets";
import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Pets from "../components/Pets";

function Home() {
    document.title = 'Pets App';
    const [pets, setPets] = useState([]);
    const [isLoaded, setIsLoaded] = useOutletContext();

    useEffect(() => {
        getPets().then(resp => {
            setPets(resp.pets || []);
            setIsLoaded(true);
        }).catch(err => {
            setPets([]);
            setIsLoaded(true);
        });
        // eslint-disable-next-line
    }, []);
    return (
        <div className="app-home" data-loaded={isLoaded}>
            <Pets items={pets} />
        </div>
    );
}

export default Home;