import Search from "./ui/icons/Search";
import '../styles/SearchOverlay.css';
function SearchOverlay({ visible, setIsSearchVisible }) {
    return (
        <div className={visible ? 'app-search visible' : 'app-search'}>
            <button className="app-search-close" onClick={() => setIsSearchVisible(false)}>
                &times;
            </button>
            <form className="app-search-form" action="/search" method="get">
                <div>
                    <input type="text" name="s" />
                    <button type="submit">
                        <Search />
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SearchOverlay;