import Icon from "./Icon";
import pets from './svg/pets.svg';

function PetsIcon() {
    return (
        <Icon>
            <img src={pets} alt="" />
        </Icon>
    )
}

export default  PetsIcon;