import { getPets } from "../api/pets";
import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Pets from "../components/Pets";
import Pagination from "../components/Pagination";

function PetsIndex() {
    document.title = 'Pets | Pets App';
    const [pets, setPets] = useState([]);
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);
    const [isLoaded, setIsLoaded] = useOutletContext();

    const getPetsPage = (index = 1) => {
        getPets(index).then(resp => {
            setPets(resp.pets || []);
            setPages(resp.pages || 1);
            setPage(resp.page || 1);
            setIsLoaded(true);
        }).catch(err => {
            setPets([]);
            setPages(1);
            setPage(1);
            setIsLoaded(true);
        });
    };

    useEffect(() => {
        getPetsPage();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="app-pets-index" data-loaded={isLoaded}>
            <Pets items={pets} />
            <Pagination pages={pages} page={page} getPetsPage={getPetsPage} setPage={setPage} />
        </div>
    );
}

export default PetsIndex;